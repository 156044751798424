<template>
  <div class="container mw-fit-content">

    <Table
      :thead="thead"
      :data="plans"
      :search="false"
      :excel="false"
      :searchBox="true"
      tableTitle="Üyelik Planları Listesi"
    >
      <template v-slot:cell-name="{ row: data }">
        {{ data.name }}
      </template>
      <template v-slot:cell-description="{ row: data }">
        {{ data.description }}
      </template>
      <template v-slot:cell-notificationLimitPerMonth="{ row: data }">
        {{ data.notificationLimitPerMonth }}
      </template>
      <template v-slot:cell-effectiveFrom="{ row: data }">
        {{ data.effectiveFrom }}
      </template>
      <template v-slot:cell-effectiveUntil="{ row: data }">
        {{ data.effectiveUntil }}
      </template>
      <template v-slot:cell-durationInMonths="{ row: data }">
        {{ data.durationInMonths }}
      </template>
    </Table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plans: [],
      subscriptions: [],
    };
  },
  created() {
    this.getSubscriptionPlans();
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Üyelik Planları",
      },
    ]);
  },
  methods: {
    getSubscriptionPlans() {
      this.$axios.get("/subscription-plan/").then((response) => {
        this.plans = [];
        response.data.filter((plan) => {
          this.plans.push({
            id: plan.id,
            name: plan.name,
            description: plan.description,
            notificationLimitPerMonth: plan.notification_limit_per_month,
            effectiveFrom: plan.effective_from,
            effectiveUntil: plan.effective_until,
            durationInMonths: plan.duration_in_months,
          });
        });
      });
    },
  },
  setup() {
    const thead = [
      {
        label: "Ad",
        value: "name",
        searchable: false,
      },
      {
        label: "Açıklama",
        value: "description",
        searchable: false,
      },
      {
        label: "Aylık Bildirim Limit",
        value: "notificationLimitPerMonth",
        searchable: false,
      },
      {
        label: "Başlangıç",
        value: "effectiveFrom",
        searchable: false,
      },
      {
        label: "Bitiş",
        value: "effectiveUntil",
        searchable: false,
      },
      {
        label: "Toplam Ay",
        value: "durationInMonths",
        searchable: false,
      },
    ];
    return {
      thead
    };
  },
};
</script>